module.exports = {
  siteTitle: 'Ryan Srofe',                                          // Site title for SEO
  siteTitleAlt: "Hi, I'm Ryan Srofe",                             // This allows an alternative site title for SEO schema.
  publisher: 'Ryan Srofe',                                          // Organization name used for SEO schema
  siteDescription: 'An award winning designer, developer, and creative technologist.',
  siteKeywords: 'web design, web development, UX design, UI design, front-end development',
  siteUrl: 'https://ryansrofe.com',                                 // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
  devUrl: 'https://dev--eager-gates-fbef29.netlify.app/',         // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
  author: 'Ryan Srofe',                                                  // Author for RSS author segment and SEO schema
  authorUrl: 'https://ryansrofe.com',                               // URL used for author and publisher schema, can be a social profile or other personal site
  userTwitter: '',                                        // Change for Twitter Cards
  shortTitle: 'Ryan Srofe',                                         // Used for App manifest e.g. Mobile Home Screen
  shareTitle: "Hi, I'm Ryan Srofe",                               // Open Graph Default Title
  shareDescription: 'An award winning designer, developer, and creative technologist.',                // Open Graph Default Description
  shareImage: '/share/share-f.jpg',                                 // Open Graph Default Share Image. 1200x630 is recommended
  shareImageWidth: 1200,                                            // Change to the width of your default share image
  shareImageHeight: 630,                                            // Change to the height of your default share image
  shareImageVert: '/share/share-p.jpg',                             // Open Graph Default Share Image. 1200x630 is recommended
  shareImageVertWidth: 600,                                         // Change to the width of your default share image
  shareImageVertHeight: 900,                                        // Change to the height of your default share image
  siteLogo: '/logos/logo-512.png',                                  // Logo used for SEO, RSS, and App manifest
  backgroundColor: '#000000',                                       // Used for Offline Manifest
  themeColor: '#FFFFFF',                                            // Used for Offline Manifest
  copyright: 'Copyright © 2021 Ryan Srofe',                         // Copyright string for the RSS feed
  socialMedia: [
    {
      name: "instagram",
      url: "https://instagram.com/abeardedpaddler",
    },
    {
      name: "linkedin",
      url: "https://www.linkedin.com/in/ryan-srofe",
    },
    {
      name: "dribbble",
      url: "https://dribbble.com/blackboard-creative",
    },
    {
      name: "gitHub",
      url: "https://github.com/blackboardcreative",
    },
    {
      name: "email",
      url: "mailto:rsrofe@gmail.com",
    }
  ]
}
