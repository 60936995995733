import React from "react";
import Helmet from 'react-helmet';
import config from '../../utils/siteConfig';
// import favicon from '../media/favicon.ico';


const Template = (props) => {

  const { children } = props;

  return (    
    <div className="root">
      
      <Helmet>
        <title>{config.siteTitle}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {/* <link rel="icon" href={favicon} /> */}
      </Helmet>

      {children}
  
    </div>
  )
}

export default Template
