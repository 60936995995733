import React, { Component } from 'react'
import Helmet from 'react-helmet'

import config from '../../utils/siteConfig'


class SEO extends Component {
  render() {
    const { metaData, pagePath, pageSEO } = this.props;
    let title;
    let description;
    let keywords;
    let shareTitle;
    let shareFacebook;
    let shareTwitter;
    let shareImage;
    let shareImageVert;
    let imgWidth;
    let imgHeight;
    let imgVertWidth;
    let imgVertHeight;
    let pageUrl;

    // Set Default OpenGraph Parameters for Fallback
    title = config.siteTitle;
    description = config.siteDescription;
    keywords = config.siteKeywords;
    shareTitle = config.shareTitle;
    shareFacebook = config.shareDescription;
    shareTwitter = config.shareDescription;
    shareImage = config.siteUrl + config.shareImage;
    imgWidth = config.shareImageWidth;
    imgHeight = config.shareImageHeight;
    shareImageVert = config.siteUrl + config.shareImageVert;
    imgVertWidth = config.shareImageVertWidth;
    imgVertHeight = config.shareImageVertHeight;
    pageUrl = config.siteUrl;

    // Set Up Default Website Schema
    const schemaOrgJSONLD = [
      {
        '@context': 'http://schema.org',
        '@type': 'WebSite',
        url: config.siteUrl,
        name: config.siteTitle,
        alternateName: config.siteTitleAlt ? config.siteTitleAlt : ''
      }
    ]

    // Set Parameters for Page SEO (this happens for subpages only)
    if (pageSEO) {
      // set title
      title = metaData.title + " | " + config.siteTitle;
      // set meta description
      description = metaData.description;
      // set share title
      shareTitle = title;
      // set share description
      shareFacebook = description;
      shareTwitter = description;
      // set page url
      pageUrl = config.siteUrl + pagePath;

      schemaOrgJSONLD.push(
        {
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          url: pageUrl,
          name: metaData.title,
        },
        {
          '@context': 'http://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: [
            {
              '@type': 'ListItem',
              position: 1,
              item: {
                '@id': config.siteUrl,
                name: config.siteTitle,
              },
            },
            {
              '@type': 'ListItem',
              position: 2,
              item: {
                '@id': pageUrl,
                name: metaData.title,
              },
            },
          ],
        }
      )
    }


    return (
      <Helmet>
        {/* General tags */}
        <title>{title}</title>
        <meta name="image" content={shareImage} />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />

        {/* Schema.org tags */}
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJSONLD)}
        </script>

        {/* OpenGraph tags */}
        <meta property="og:title" content={shareTitle} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:image" content={shareImage} />
        <meta property="og:image:width" content={imgWidth} />
        <meta property="og:image:height" content={imgHeight} />
        <meta property="og:image" content={shareImageVert} />
        <meta property="og:image:width" content={imgVertWidth} />
        <meta property="og:image:height" content={imgVertHeight} />
        <meta property="og:description" content={shareFacebook} />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={config.userTwitter ? config.userTwitter : ''} />
        <meta name="twitter:title" content={shareTitle} />
        <meta name="twitter:image" content={shareImage} />
        <meta name="twitter:description" content={shareTwitter} />
      </Helmet>
    )
  }
}

export default SEO
